.call-list {
  margin: 0;
  padding: 0rem 0rem 0rem 1rem;
  overflow-y: hidden;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
