@import '../../variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 1rem 1rem 2rem 1rem;

  .logo-column {
    flex: 1;

    .logo {
      cursor: pointer;
      width: 30rem;
    }
  }

  .office-name{
    h1 {
      color: $primary-color;
      cursor: pointer;
    }
  }
}
