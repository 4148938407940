$button-color: #4c9fff;

.login {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .form-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      width: 11rem;
      padding-bottom: 3rem;
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem 5rem 1rem 5rem;
      box-shadow:  0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

      .user-default {
        width: 7rem;
        padding-bottom: 4rem;
        padding-top: 7rem;
      }

      input {
        width: 15rem;
        padding: 0.25rem 0.5rem;
      }
      .input-div {
        padding: 0.25rem 0.5rem;
      }
      .error-message {
        color: #F04B4B;
        padding-top: .5rem;
      }
    }
    .actions {
      text-align: right;
      padding-top: 1rem;
      padding-bottom: 6rem;

      button {
        width: 16.25rem;
        padding: .75rem 1rem .75rem 1rem;
        background-color: $button-color;
        border-width: 0px;
        color: white;
        font-weight: bold;
      }

      :hover {
        cursor: pointer;
      }
    }

  }

}
