.office-selector {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  h1 {
    text-align: center;
  }

  .list-offices {
    width: 450px;
    align-self: center;
    box-shadow:  0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

    :hover {
    box-shadow: 0 0 6px rgba(35, 173, 255, 1);
    }

    .box-office{
      display: flex;
      cursor: pointer;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 1.5rem 0rem 1.5rem 0rem;

    }

  }


}
