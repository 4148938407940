@import '../../variables';

.call-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 1rem 1rem 1rem 1rem;
  border-top: 1px #BEBEBE solid;

  .patient {

    .last-name {
      font-size: 2rem;
      color: $primary-color;
      display: flex;
      align-items: center;
    }

    .first-name {
      color: $secondary-color;
      font-size: 1.5rem;
    }

    .to-be-called-again {
      margin-left: 3rem;
      color: $secondary-color;
      font-size: 1rem;
    }
  }

  .office-room {
    text-align-last:end;

    .number {
      color: $primary-color;
      font-size: 2rem;
    }

    .name {
      color: $secondary-color;
      font-size: 1.5rem;
    }
  }
}
