@import '../../variables';

.last-call {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem 2rem 1rem;

  .office-room {

    padding-left: 3rem;
    .description {
      font-weight: bold;
      color: $primary-color;
      font-size: 2.2rem;
    }

    .equipment {
      color: $secondary-color;
      font-size: 1.5rem;
    }
  }

  .patient {
    font-size: 4rem;

    .last-name {
      font-weight: bold;
      color: $primary-color;
    }

    .first-name {
      color: $secondary-color;
    }
  }
}
