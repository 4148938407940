.board {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @-moz-keyframes flash{ 
    0%   { opacity: 1.0; }
    50%  { opacity: 0.0; }
    100% { opacity: 1.0; }
  }

  @-webkit-keyframes flash {
    0%   { opacity: 1.0; }
    50%  { opacity: 0.0; }
    100% { opacity: 1.0; }
  }

  @keyframes flash {
    0%   { opacity: 1.0; }
    50%  { opacity: 0.0; }
    100% { opacity: 1.0; }
  }

  @keyframes message {
    0%   { opacity: 1.0; }
    15%  { opacity: 1.0; }
    16%  { opacity: 0.0; }
    99%  { opacity: 0.0; }
    100% { opacity: 1.0; }
  }
  .alert-message{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: bold;

    text-align: center;
    z-index: 3;
    margin-bottom: 15px;
    margin-left: 12px;

    color: red;
    background-color: white;
    width: 99%;

  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-grow: 1;
    padding: 0rem 1rem 1rem 1rem;

    .history {
      display: flex;
      flex: 1;
      position: relative;
    }
  }

  .blink-enter.blink-enter-active {
    animation-name: flash;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 3;
    animation-delay: .5s;
  }
}
